import Swal from "sweetalert2"

export default function useDelete(deleteFunction, successMessage, fetchDataFunction) {
    const confirmDelete = (item) => {
        Swal.fire({
            title: "آیا مطمئن هستید؟",
            text: "در صورت حذف شما دیگر نمی توانید آن را برگردانید!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "بله",
            cancelButtonText: "لغو",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFunction(item).then((res) => {
                    console.log(res);
                    Swal.fire({
                        title: "حذف شد!",
                        text: successMessage,
                        icon: "success",
                        confirmButtonText: "باشه",
                    });
                    fetchDataFunction();
                });
            }
        });
    };

    return {
        confirmDelete
    };
}
