<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"

const users = computed(() => store.state.users)

import useUsers from "../../use/useUsers"
import useDebouncedFunction from "../../use/useDebouncedFunction"

import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const { userStatus, search, perPage, sortField, sortDirection, getUsers } =
  useUsers()
const { handleInput } = useDebouncedFunction(getUsers)

const emit = defineEmits(["clickEdit", "clickChangePass"])

onMounted(() => {
  getUsers()
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getUsers(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editUser = (usr) => {
  emit("clickEdit", usr)
}

const changePasswordUser = (usrId) => {
  emit("clickChangePass", usrId)
}

function sortUsers(field) {
  if (field === sortField.value) {
    if (sortDirection.value === "desc") {
      sortDirection.value = "asc"
    } else {
      sortDirection.value = "desc"
    }
  } else {
    sortField.value = field
    sortDirection.value = "asc"
  }

  getUsers()
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="جستجوی کاربر، نام، ایمیل و...."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6">
            <select
              @change="getUsers(null)"
              v-model="userStatus"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>وضعیت کاربر را انتخاب کنید</option>
              <option value="0">غیرفعال</option>
              <option value="1">فعال</option>
            </select>
          </b-col>
          <b-col sm="6">
            <select
              @change="getUsers(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف ها</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="userTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="name"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortUsers('name')"
              >
                نام
              </TableHeaderCell>
              <TableHeaderCell
                field="email"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortUsers('email')"
              >
                ایمیل
              </TableHeaderCell>
              <TableHeaderCell
                field="created_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortUsers('created_at')"
              >
                تاریخ ثبت
              </TableHeaderCell>
              <TableHeaderCell
                field="updated_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortUsers('updated_at')"
              >
                تاریخ ویرایش
              </TableHeaderCell>
              <TableHeaderCell
                field="status"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortUsers('status')"
              >
                وضعیت
              </TableHeaderCell>
              <th data-sort="action">عملیات</th>
            </tr>
          </thead>
          <tbody class="list" v-if="users.loading || !users.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="users.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ کاربری یافت نشد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(user, index) of users.data" :key="index">
              <td class="user_frist_name">{{ user.name }}</td>
              <td class="email">{{ user.email }}</td>
              <td class="date">{{ user.created_at }}</td>
              <td class="updated_at">
                {{ user.updated_at }}
              </td>
              <td class="status d-flex flex-wrap gap-2">
                <span
                  class="badge"
                  :class="{
                    'bg-success-subtle text-success': user.status == 1,
                    'bg-danger-subtle text-danger': user.status == 0,
                  }"
                  >{{ user.status ? "فعال" : "غیرفعال" }}</span
                >
                <span
                  class="badge"
                  :class="{
                    'bg-secondary-subtle text-secondary':
                      user.email_verified_at !== null,
                    'bg-warning-subtle text-warning':
                      user.email_verified_at === null,
                  }"
                  >{{
                    user.email_verified_at ? "تایید ایمیل" : "عدم تایید ایمیل"
                  }}</span
                >
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item change-password"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="تغییر کلمه عبور"
                  >
                    <b-link
                      variant="text-warning"
                      class="d-inline-block change-password-btn"
                      @click="changePasswordUser(user)"
                    >
                      <i class="ri-lock-password-fill fs-16"></i>
                    </b-link>
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="ویرایش کاربر"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editUser(user)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-end" v-if="users.total > users.limit">
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of users.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<style>
.vpd-icon-btn {
  margin-bottom: 0 !important;
  padding: 0.5rem 0.9rem !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.5rem 0.9rem !important;
}
</style>
