const ROLE = {
    ADMIN: 'admin',
};

export function setUser(state, user) {
    if (user) {
        state.user.data = user;
        state.user.loggedIn = true,
            state.user.isAdmin = user.ur === ROLE.ADMIN;

        // Save user data to localStorage
        localStorage.setItem('USER', JSON.stringify(state.user.data));

        if (state.user.isAdmin) {
            localStorage.setItem('USERROLE', 'Admin');
        }
        localStorage.setItem('LOGGEDIN', state.user.loggedIn)
    } else {
        // Clear user data from localStorage
        localStorage.removeItem('USER');
        localStorage.removeItem('USERROLE');
        localStorage.removeItem('LOGGEDIN');
    }
}

export function setToken(state, token) {
    state.user.token = token;
    if (token) {
        localStorage.setItem('TOKEN', token);
    } else {
        localStorage.removeItem('TOKEN')
    }
}

export function setUsers(state, [loading, data = null]) {
    if (data) {
        state.users = {
            ...state.users,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.users.loading = loading;
}

export function setSaints(state, [loading, data = null]) {
    if (data) {
        state.saints = {
            ...state.saints,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.saints.loading = loading;
}

export function setLiturgies(state, [loading, data = null]) {
    if (data) {
        state.liturgies = {
            ...state.liturgies,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.liturgies.loading = loading;
}

export function setAllLiturgyCategories(state, [loading, data = null]) {
    if (data) {
        state.allLiturgyCategories = {
            ...state.allLiturgyCategories,
            data: data.data,
        }
    }
    state.allLiturgyCategories.loading = loading;
}

export function setCategoriesWithLiturgies(state, [loading, data = null]) {
    if (data) {
        state.categoriesWithLiturgies = {
            ...state.categoriesWithLiturgies,
            data: data.data,
        }
    }
    state.categoriesWithLiturgies.loading = loading;
}

export function setMasses(state, [loading, data = null]) {
    if (data) {
        state.masses = {
            ...state.masses,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.masses.loading = loading;
}

export function setPrayers(state, [loading, data = null]) {
    if (data) {
        state.prayers = {
            ...state.prayers,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.prayers.loading = loading;
}

export function setAllPrayerCategories(state, [loading, data = null]) {
    if (data) {
        state.allPrayerCategories = {
            ...state.allPrayerCategories,
            data: data.data,
        }
    }
    state.allPrayerCategories.loading = loading;
}

export function setCategoriesWithPrayers(state, [loading, data = null]) {
    if (data) {
        state.categoriesWithPrayers = {
            ...state.categoriesWithPrayers,
            data: data.data,
        }
    }
    state.categoriesWithPrayers.loading = loading;
}

export function setLibraries(state, [loading, data = null]) {
    if (data) {
        state.libraries = {
            ...state.libraries,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.libraries.loading = loading;
}

export function setUploadComplete(state) {
    state.uploadComplete = true;
}

export function setUploadCompleteFalse(state) {
    state.uploadComplete = false;
}

export function setBlogs(state, [loading, data = null]) {
    if (data) {
        state.blogs = {
            ...state.blogs,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.blogs.loading = loading;
}

export function setAllTags(state, [loading, data = null]) {
    if (data) {
        state.allTags = {
            ...state.allTags,
            data: data.data,
        }
    }
    state.allTags.loading = loading;
}

export function setTags(state, [loading, data = null]) {
    if (data) {
        state.tags = {
            ...state.tags,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.tags.loading = loading;
}

export function setComments(state, [loading, data = null]) {
    if (data) {
        state.comments = {
            ...state.comments,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.comments.loading = loading;
}

export function setCommentCount(state, [data = null]) {
    if (data) {
        state.commentCount = data
    }
}

export function setMessages(state, [loading, data = null]) {
    if (data) {
        state.messages = {
            ...state.messages,
            data: data.data,
            links: data.meta?.links,
            page: data.meta.current_page,
            limit: data.meta.per_page,
            from: data.meta.from,
            to: data.meta.to,
            total: data.meta.total,
        }

    }

    state.messages.loading = loading;
}

export function seMessageCount(state, [data = null]) {
    if (data) {
        state.messageCount = data
    }
}







