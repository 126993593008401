<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useSaints from "../../use/useSaints"
import useDebouncedFunction from "../../use/useDebouncedFunction"
import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const saints = computed(() => store.state.saints)
const {
  search,
  date,
  perPage,
  sortDirection,
  getSaints,
  sortSaints,
  deleteSaint,
} = useSaints()

const sortField = ref("date")

const { handleInput } = useDebouncedFunction(getSaints)

const emit = defineEmits(["clickEdit", "clickView"])

onMounted(() => {
  getSaints()
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getSaints(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editSaint = (saint) => {
  emit("clickEdit", saint)
}

const viewSaint = (saint) => {
  emit("clickView", saint)
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="5">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="جستجوی قدیس روزانه عنوان و...."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="7">
        <b-row class="g-3">
          <b-col sm="8">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  فیلتر بر اساس تاریخ:
                </span>
              </div>
              <input
                type="date"
                @change="getSaints(null)"
                v-model="date"
                class="form-control"
              /></div
          ></b-col>
          <b-col sm="4">
            <select
              @change="getSaints(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف ها</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="saintTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="title"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortSaints('title')"
              >
                عنوان
              </TableHeaderCell>
              <TableHeaderCell
                field="date"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortSaints('date')"
              >
                تاریخ
              </TableHeaderCell>

              <th data-sort="action">عملیات</th>
            </tr>
          </thead>
          <tbody class="list" v-if="saints.loading || !saints.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="saints.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ قدیس روزانه ای یافت نشد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(saint, index) of saints.data" :key="index">
              <td class="saint_title">{{ saint.title }}</td>
              <td class="date">{{ saint.date }}</td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item view"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="مشاهده قدیس روزانه"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block view-item-btn"
                      @click="viewSaint(saint)"
                    >
                      <i class="ri-eye-fill fs-16"></i>
                    </BLink>
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="ویرایش قدیس روزانه"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editSaint(saint)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>

                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="حذف قدیس روزانه"
                  >
                    <BLink
                      variant="text-danger"
                      class="d-inline-block remove-item-btn"
                      @click="deleteSaint(saint)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="saints.total > saints.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of saints.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<style>
.vpd-icon-btn {
  margin-bottom: 0 !important;
  padding: 0.5rem 0.9rem !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.5rem 0.9rem !important;
}
</style>
