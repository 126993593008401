<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useSaints from "../../use/useSaints"

const props = defineProps({
  saint: {
    required: true,
    type: Object,
  },
})

const saintData = ref({
  id: props.saint.id,
  title: props.saint.title,
  text: props.saint.text,
  date: props.saint.date,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, closeModal } = useSaints(props, emit, saintData)

onUpdated(() => {
  saintData.value = {
    id: props.saint.id,
    title: props.saint.title,
    text: props.saint.text,
    date: props.saint.date,
  }
})
</script>

<template>
  <!-- view saint modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="اطلاعات قدیس روزانه"
    class="v-modal-custom"
    scrollable
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <h6 class="fs-15">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">تاریخ نمایش قدیس: </strong> {{ saintData.date }}
      </h6>
      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">عنوان قدیس: </strong> {{ saintData.title }}
      </h6>

      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">متن قدیس: </strong>
      </h6>
      <div class="py-2 px-3" v-html="saintData.text"></div>
    </b-overlay>
    <div class="modal-footer v-modal-footer">
      <BButton type="button" variant="light" @click="closeModal">بستن </BButton>
    </div>
  </BModal>
</template>
