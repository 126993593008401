<script setup>
import { computed, ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import BlogModal from "./BlogModal.vue"
import BlogEditModal from "./BlogEditModal.vue"
import BlogsTable from "./BlogsTable.vue"
import BlogViewModal from "./BlogViewModal.vue"

const title = "مدیریت مقالات و ویدیوها"
const pageTitle = "داشبورد"

const DEFAULT_BLOG = {
  id: "",
  title: "",
  text: "",
  post_type: "",
  file: "",
  blog_image: "",
}

const allTags = computed(() =>
  store.state.allTags.data.map((item) => {
    return {
      value: item.id,
      label: item.title,
    }
  })
)

const blogModel = ref({ ...DEFAULT_BLOG })
const showBlogModal = ref(false)
const showBlogEditModal = ref(false)
const showBlogViewModal = ref(false)

const showAddNewModal = () => {
  showBlogModal.value = true
}

const showEditModal = () => {
  showBlogEditModal.value = true
}

const showViewModal = () => {
  showBlogViewModal.value = true
}

function editBlog(blog) {
  store.dispatch("getBlog", blog.id).then(({ data }) => {
    blogModel.value = data.data
    showEditModal()
  })
}

const viewBlog = (blog) => {
  store.dispatch("getBlog", blog.id).then(({ data }) => {
    blogModel.value = data.data
    showViewModal()
  })
}

const onModalClose = () => {
  blogModel.value = { ...DEFAULT_BLOG }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="blogList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست مقالات و ویدیوها</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <router-link
                    class="btn btn-primary waves-effect waves-light"
                    :to="{ name: 'tags' }"
                  >
                    <i class="ri-price-tag-3-fill align-bottom me-1"></i> مدیریت
                    برچسب ها
                  </router-link>
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> افزودن پست
                    جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- blogs table  -->

          <BlogsTable @clickEdit="editBlog" @clickView="viewBlog" />

          <!-- add and edit blog modal  -->

          <BlogModal
            v-model="showBlogModal"
            :blog="blogModel"
            :allTags="allTags"
            @close="onModalClose"
          />

          <BlogViewModal
            v-model="showBlogViewModal"
            :blog="blogModel"
            @close="onModalClose"
          />

          <BlogEditModal
            v-model="showBlogEditModal"
            :blog="blogModel"
            :allTags="allTags"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.multiselect-clear {
  padding: 0 0 0 var(--ms-px, 0.875rem) !important;
}
.multiselect-caret {
  margin: 0 0 0 var(--ms-px, 0.875rem) !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
