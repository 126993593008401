<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useUsers from "../../use/useUsers"
import usePersianTools from "../../use/usePersianTools"

const props = defineProps({
  user: {
    required: true,
    type: Object,
  },
})

const userData = ref({
  id: props.user.id,
  name: props.user.name,
  email: props.user.email,
  password: "",
  password_confirmation: "",
  status: props.user.status,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useUsers(
  props,
  emit,
  userData
)

const { convertPersianToEnglish } = usePersianTools()

onUpdated(() => {
  userData.value = {
    id: props.user.id,
    name: props.user.name,
    email: props.user.email,
    password: "",
    password_confirmation: "",
    status: props.user.status,
  }
})
</script>

<template>
  <!-- add user modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="افزودن کابر جدید"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="userfirstname" class="form-label"> نام</label>
            <input
              type="text"
              id="userfirstname"
              class="form-control"
              placeholder="نام کامل کاربر"
              v-model="userData.name"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.name">
                <span>{{ errors.name[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="email" class="form-label">ایمیل</label>
            <input
              type="email"
              id="email"
              class="form-control"
              placeholder="ایمیل کاربر"
              v-model="userData.email"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.email">
                <span>{{ errors.email[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="password" class="form-label">کلمه عبور</label>
            <input
              type="password"
              id="password"
              class="form-control"
              placeholder="کلمه عبور (پسورد)"
              v-model="userData.password"
              @input="
                convertPersianToEnglish(
                  $event.target.value,
                  userData,
                  'password'
                )
              "
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.password">
                <span>{{ errors.password[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="confirm-password" class="form-label"
              >تایید کلمه عبور</label
            >
            <input
              type="password"
              id="confirm-password"
              class="form-control"
              placeholder="تایید کلمه عبور (تکرار کلمه عبور)"
              v-model="userData.password_confirmation"
              @input="
                convertPersianToEnglish(
                  $event.target.value,
                  userData,
                  'password'
                )
              "
            />
          </div>

          <div class="mb-3">
            <label for="status" class="form-label">وضعیت</label>
            <select
              class="form-control"
              data-trigger
              id="statusfield"
              v-model="userData.status"
            >
              <option value="">وضعیت</option>
              <option value="0">غیرفعال</option>
              <option value="1">فعال</option>
            </select>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.status">
                <span>{{ errors.status[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">افزودن کاربر</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
