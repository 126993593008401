<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useComments from "../../use/useComments"
import useDebouncedFunction from "../../use/useDebouncedFunction"

const comments = computed(() => store.state.comments)

const { commentStatus, search, perPage, getComments } = useComments()
const { handleInput } = useDebouncedFunction(getComments)

const emit = defineEmits(["clickEdit"])

onMounted(() => {
  getComments()
})

const shortenCommentText = (text) => {
  if (text.length <= 50) {
    return text
  } else {
    return text.slice(0, 50) + "..."
  }
}

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getComments(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editComment = (comm) => {
  emit("clickEdit", comm)
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="جستجوی نظر و..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6">
            <select
              @change="getComments(null)"
              v-model="commentStatus"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>انتخاب وضعیت نظر</option>
              <option value="0">تائید نشده</option>
              <option value="1">تائید شده</option>
            </select>
          </b-col>

          <b-col sm="6">
            <select
              @change="getComments(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="commentTable">
          <thead class="table-light text-muted">
            <tr>
              <th class="sort" data-sort="comment_text">نظر</th>
              <th class="sort" data-sort="user">نام کاربر</th>
              <th class="sort" data-sort="blog">مربوط به پست</th>
              <th class="sort" data-sort="date">تاریخ</th>
              <th class="sort" data-sort="status">وضعیت</th>
              <th class="sort" data-sort="action">عملیات</th>
            </tr>
          </thead>
          <tbody class="list" v-if="comments.loading || !comments.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="comments.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ نظری وجود ندارد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(comment, index) of comments.data" :key="index">
              <td class="comment-text">
                {{ shortenCommentText(comment.commentText) }}
              </td>
              <td class="user">{{ comment.user }}</td>
              <td class="blog">{{ comment.blog }}</td>
              <td class="date">{{ comment.created_at }}</td>
              <td class="status">
                <span
                  class="badge"
                  :class="{
                    'bg-success-subtle text-success':
                      comment.commentStatus == 1,
                    'bg-danger-subtle text-danger': comment.commentStatus == 0,
                  }"
                  >{{
                    comment.commentStatus ? "تائید شده" : "تائید نشده"
                  }}</span
                >
              </td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Edit"
                  >
                    <b-link
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editComment(comment)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </b-link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="comments.total > comments.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of comments.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
