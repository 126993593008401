<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useLiturgies from "../../use/useLiturgies"

import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const liturgies = computed(() => store.state.liturgies)

const categoriesWithLiturgies = computed(
  () => store.state.categoriesWithLiturgies
)

const {
  date,
  categoryId,
  perPage,
  sortDirection,
  getLiturgies,
  sortLiturgies,
  deleteLiturgy,
} = useLiturgies()

const sortField = ref("date")

const emit = defineEmits(["clickEdit", "clickView"])

onMounted(() => {
  getLiturgies()
  store.dispatch("getCategoriesWithLiturgies")
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getLiturgies(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editLiturgy = (liturgy) => {
  emit("clickEdit", liturgy)
}

const viewLiturgy = (liturgy) => {
  emit("clickView", liturgy)
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="5">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              فیلتر بر اساس تاریخ:
            </span>
          </div>
          <input
            type="date"
            @change="getLiturgies(null)"
            v-model="date"
            class="form-control"
          />
        </div>
      </b-col>

      <b-col xl="7">
        <b-row class="g-3">
          <b-col sm="8">
            <select
              @change="getLiturgies(null)"
              v-model="categoryId"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="">انتخاب دسته بندی</option>
              <option
                v-for="category in categoriesWithLiturgies.data"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </b-col>

          <b-col sm="4">
            <select
              @change="getLiturgies(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف</option>
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="liturgyTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="liturgy_category_id "
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortLiturgies('liturgy_category_id ')"
              >
                عنوان دسته بندی
              </TableHeaderCell>
              <TableHeaderCell
                field="date"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortLiturgies('date')"
              >
                تاریخ نمایش
              </TableHeaderCell>

              <th data-sort="action">عملیات</th>
            </tr>
          </thead>

          <tbody
            class="list"
            v-if="liturgies.loading || !liturgies.data.length"
          >
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="liturgies.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ نیایش روزی وجود ندارد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(liturgy, index) of liturgies.data" :key="index">
              <td class="liturgy-category-title">
                {{ liturgy.category_title }}
              </td>
              <td class="date">
                {{ liturgy.date }}
              </td>

              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item view"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="مشاهده نیایش روز"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block view-item-btn"
                      @click="viewLiturgy(liturgy)"
                    >
                      <i class="ri-eye-fill fs-16"></i>
                    </BLink>
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="ویرایش نیایش روز"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editLiturgy(liturgy)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>

                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="حذف نیایش روز"
                  >
                    <BLink
                      variant="text-danger"
                      class="d-inline-block remove-item-btn"
                      @click="deleteLiturgy(liturgy)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="liturgies.total > liturgies.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of liturgies.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
