<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useLiturgies from "../../use/useLiturgies"

const props = defineProps({
  liturgy: {
    required: true,
    type: Object,
  },
})

const liturgyData = ref({
  id: props.liturgy.id,
  liturgy_category_id: props.liturgy.liturgy_category_id,
  category_title: props.liturgy.category_title,
  text: props.liturgy.text,
  date: props.liturgy.date,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, closeModal } = useLiturgies(props, emit, liturgyData)

onUpdated(() => {
  liturgyData.value = {
    id: props.liturgy.id,
    liturgy_category_id: props.liturgy.liturgy_category_id,
    category_title: props.liturgy.category_title,
    text: props.liturgy.text,
    date: props.liturgy.date,
  }
})
</script>

<template>
  <!-- view liturgy modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="اطلاعات نیایش روز"
    class="v-modal-custom"
    scrollable
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <h6 class="fs-15">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">تاریخ نمایش نیایش روز: </strong>
        {{ liturgyData.date }}
      </h6>
      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">عنوان دسته بندی: </strong>
        {{ liturgyData.category_title }}
      </h6>

      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">متن نیایش روز: </strong>
      </h6>
      <div class="py-2 px-3" v-html="liturgyData.text"></div>
    </b-overlay>
    <div class="modal-footer v-modal-footer">
      <BButton type="button" variant="light" @click="closeModal">بستن </BButton>
    </div>
  </BModal>
</template>
