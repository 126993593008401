import * as persianTools from "@persian-tools/persian-tools";

export default function usePersianTools() {
    const convertPersianToEnglish = (value, obj, key) => {
        if (value && /[۰-۹]/.test(value)) {
            const englishValue = persianTools.digitsFaToEn(value)
            obj[key] = englishValue
            return englishValue
        } else {
            obj[key] = value
            return value
        }
    }


    return {
        convertPersianToEnglish
    }
}