<script setup>
import { ref, onUpdated, onMounted, defineProps, defineEmits } from "vue"
import usePrayers from "../../use/usePrayers"

import Editor from "ckeditor5-custom-build/build/ckeditor"
import CKEditor from "@ckeditor/ckeditor5-vue"

import store from "../../state/store"

const props = defineProps({
  modelValue: Boolean,
  prayer: {
    required: true,
    type: Object,
  },
  allPrayerCategories: {
    required: true,
    type: Object,
  },
})

const prayerData = ref({
  id: props.prayer.id,
  prayer_category_id: props.prayer.prayer_category_id,
  title: props.prayer.title,
  text: props.prayer.text,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = usePrayers(
  props,
  emit,
  prayerData
)

onUpdated(() => {
  prayerData.value = {
    id: props.prayer.id,
    prayer_category_id: props.prayer.prayer_category_id,
    title: props.prayer.title,
    text: props.prayer.text,
  }
})

onMounted(() => {
  store.dispatch("getAllPrayerCategories")
})

/**
 *! start editor
 */

const editorConfig = {
  language: "fa",
  ckfinder: {
    options: {
      language: "fa",
    },
  },
}

const editor = Editor
const ckeditor = CKEditor.component

/**
 *! end editor
 */
</script>

<template>
  <!-- add and edit prayer modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title=" ویرایش ویژگی"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="mb-3">
            <label for="name" class="form-label">دسته بندی </label>
            <select
              class="form-select mb-3"
              v-model="prayerData.prayer_category_id"
            >
              <option value="">انتخاب دسته بندی</option>
              <option
                v-for="category in allPrayerCategories.data"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.prayer_category_id">
                <span>{{ errors.prayer_category_id[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="prayertitle" class="form-label"> عنوان دعا</label>
            <input
              type="text"
              id="prayertitle"
              class="form-control"
              placeholder="عنوان دعا"
              v-model="prayerData.title"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.title">
                <span>{{ errors.title[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="prayertext" class="form-label">متن دعا</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="prayerData.text"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.text">
                <span>{{ errors.text[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success"
              >ویرایش دعا</b-button
            >
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
