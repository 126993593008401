import { ref, computed, watchEffect } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useSortData from './useSortData';
import useDelete from './useDelete';

export default function useBlogs(props, emit, blogData) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const createdAt = ref("")
    const postType = ref("")
    const perPage = ref(10)
    const loading = ref(false)
    const errors = ref({})
    const frontErrorMessage = ref('');
    const blogId = ref("")

    const selectedTags = ref([])

    const fileSelected = ref(false);
    const file = ref(null);
    const chunks = ref([]);
    const uploaded = ref(0);

    const fileName = ref("null")
    const fileTotalSize = ref(null)


    const progress = computed(() => {
        if (!file.value || !file.value.size) return 0;

        const fileSize = file.value.size;
        const uploadedSize = uploaded.value;
        return (Math.floor((uploadedSize * 100) / fileSize) > 100) ? 100 : Math.floor((uploadedSize * 100) / fileSize);
    })

    const formData = computed(() => {
        const formData = new FormData();
        formData.set('is_last', chunks.value.length === 1);
        formData.set('file', chunks.value[0], `${file.value.name}.part`);

        if (chunks.value.length === 1) {
            formData.append('id', blogData.value.id || '');
        }

        return formData;
    });

    const onBlogFileChange = (event) => {
        file.value = event.target.files.item(0);
        const allowedTypes = ['video/mp4'];
        if (!file.value) return

        if (!allowedTypes.includes(file.value.type)) {
            frontErrorMessage.value = 'فرمت فایل نامعتبر است. لطفاً یک فایل ویديو با فرمت mp4 انتخاب کنید.';
            return;
        }

        fileName.value = file.value.name.length >= 30
            ? file.value.name.substring(0, 31) + "... ." + file.value.name.split(".").pop()
            : file.value.name

        fileSelected.value = true;
        fileTotalSize.value = (file.value.size < 1024) ? file.value.size + 'KB' : (file.value.size / (1024 * 1024)).toFixed(2) + 'MB';

        frontErrorMessage.value = '';

        createChunks();
    };

    const upload = () => {
        store.dispatch("uploadBlogFile", { formData, uploaded })
            .then((response) => {

                if (response.data.uploaded === '100%') {
                    blogId.value = response.data.id;
                    store
                        .dispatch("uploadCompleteTrue")
                }
                chunks.value.shift();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const createChunks = () => {
        const size = 3 * 1024 * 1024;
        const numChunks = Math.ceil(file.value.size / size);

        for (let i = 0; i < numChunks; i++) {
            chunks.value.push(file.value.slice(
                i * size, Math.min(i * size + size, file.value.size), file.value.type
            ));
        }
    };

    watchEffect(() => {
        if (chunks.value.length > 0) {
            upload();
        }
    });


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getBlogs = (url = null) => {
        store.dispatch("getBlogs", {
            url,
            search: search.value,
            createdAt: createdAt.value,
            postType: postType.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (blogId.value) {
            blogData.value.id = blogId.value
        }
        if (blogData.value.id) {
            store.dispatch('updateBlog', { blogData, tag_id: selectedTags.value })
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("پست مورد نظر با موفقیت به روز شد!", {
                            timeout: 3000,
                        })
                        store
                            .dispatch("uploadCompleteFalse")
                        fileSelected.value = false;
                        store.dispatch("getBlogs")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createBlog", { blogData, tag_id: selectedTags.value })
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        toast.success("پست مورد نظر با موفقیت اضافه شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getBlogs")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }
    }

    const closeModal = () => {

        show.value = false
        errors.value = {}
        const imageInput = document.getElementById("BlogImage")
        imageInput.value = ""
        if (document.getElementById("blogFile")) {
            const fileInput = document.getElementById("blogFile")
            fileInput.value = ""
        }
        blogData.value.blog_image = null
        selectedTags.value = []
        frontErrorMessage.value = ""
        emit("close")
    }

    const { sortField, sortDirection, sortData } = useSortData(getBlogs);

    const sortBlogs = (field) => {
        sortData(field);
        getBlogs();
    };

    const deleteBlog = (blog) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteBlog", blog),
            'پست مورد نظر حذف شد.',
            getBlogs
        );

        confirmDelete();
    };


    return {
        show,
        search,
        createdAt,
        postType,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        frontErrorMessage,
        fileSelected,
        fileName,
        fileTotalSize,
        progress,
        selectedTags,
        getBlogs,
        onBlogFileChange,
        onSubmit,
        closeModal,
        sortBlogs,
        deleteBlog,
    }
}