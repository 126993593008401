import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useSortData from './useSortData';

export default function useTags(props, emit, tag) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const perPage = ref(10)
    const loading = ref(false)
    const errors = ref({})

    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getTags = (url = null) => {
        store.dispatch("getTags", {
            url,
            search: search.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true

        if (tag.value.id) {
            store.dispatch('updateTag', tag.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("برچسب با موفقیت به روزرسانی شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getTags")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createTag", tag.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        toast.success("برچسب با موفقیت افزوده شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getTags")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }

    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    const { sortField, sortDirection, sortData } = useSortData(getTags);

    const sortTags = (field) => {
        sortData(field);
        getTags();
    };


    return {
        show,
        search,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getTags,
        onSubmit,
        closeModal,
        sortTags
    }
}