<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useSaints from "../../use/useSaints"

import Editor from "ckeditor5-custom-build/build/ckeditor"
import CKEditor from "@ckeditor/ckeditor5-vue"

const props = defineProps({
  saint: {
    required: true,
    type: Object,
  },
})

const saintData = ref({
  id: props.saint.id,
  title: props.saint.title,
  text: props.saint.text,
  date: props.saint.date,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useSaints(
  props,
  emit,
  saintData
)

onUpdated(() => {
  saintData.value = {
    id: props.saint.id,
    title: props.saint.title,
    text: props.saint.text,
    date: props.saint.date,
  }
})

/**
 *! start editor
 */

const editorConfig = {
  language: "fa",
  ckfinder: {
    options: {
      language: "fa",
    },
  },
}

const editor = Editor
const ckeditor = CKEditor.component

/**
 *! end editor
 */
</script>

<template>
  <!-- edit saint modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="ویرایش قدیس"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="editform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="sainttitle" class="form-label"> عنوان قدیس</label>
            <input
              type="text"
              id="sainttitle"
              class="form-control"
              placeholder="عنوان قدیس روزانه"
              v-model="saintData.title"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.title">
                <span>{{ errors.title[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="sainttdate" class="form-label">تاریخ قدیس</label>
            <input
              type="date"
              id="sainttdate"
              class="form-control"
              placeholder="تاریخ قدیس روزانه"
              v-model="saintData.date"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.date">
                <span>{{ errors.date[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="saintttext" class="form-label">متن قدیس</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="saintData.text"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.text">
                <span>{{ errors.text[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">ویرایش قدیس</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
