import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useSortData from './useSortData';
import useDelete from './useDelete';

export default function useLiturgies(props, emit, liturgyData) {

    // Get toast interface
    const toast = useToast()


    const date = ref("")
    const categoryId = ref("")
    const perPage = ref(10)
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getLiturgies = (url = null) => {
        store.dispatch("getLiturgies", {
            url,
            date: date.value,
            categoryId: categoryId.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (liturgyData.value.id) {
            store.dispatch('updateLiturgy', liturgyData.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("نیایش روز با موفقیت به روز شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getLiturgies")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createLiturgy", liturgyData.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        console.log('Yes');
                        toast.success("نیایش روز مورد نظر با موفقیت اضافه شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getLiturgies")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    const { sortField, sortDirection, sortData } = useSortData(getLiturgies);

    const sortLiturgies = (field) => {
        sortData(field);
        getLiturgies();
    };

    const deleteLiturgy = (liturgy) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteLiturgy", liturgy),
            'نیایش روز مورد نظر حذف شد.',
            getLiturgies
        );

        confirmDelete();
    };


    return {
        show,
        date,
        categoryId,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getLiturgies,
        onSubmit,
        closeModal,
        sortLiturgies,
        deleteLiturgy,
    }
}