<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import LibraryModal from "./LibraryModal.vue"
import LibraryEditModal from "./LibraryEditModal.vue"
import LibrariesTable from "./LibrariesTable.vue"

const title = "مدیریت کتابخانه"
const pageTitle = "داشبورد"

const DEFAULT_LIBRARY = {
  id: "",
  name: "",
  book_image: "",
  file: "",
}

const libraryModel = ref({ ...DEFAULT_LIBRARY })
const showLibraryModal = ref(false)
const showLibraryEditModal = ref(false)

const showAddNewModal = () => {
  showLibraryModal.value = true
}

const showEditModal = () => {
  showLibraryEditModal.value = true
}

function editLibrary(library) {
  store.dispatch("getLibrary", library.id).then(({ data }) => {
    libraryModel.value = data.data
    showEditModal()
  })
}

const onModalClose = () => {
  libraryModel.value = { ...DEFAULT_LIBRARY }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="libraryList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست کتاب ها</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> افزودن کتاب
                    جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- libraries table  -->

          <LibrariesTable @clickEdit="editLibrary" />

          <!-- add and edit library modal  -->

          <LibraryModal
            v-model="showLibraryModal"
            :library="libraryModel"
            @close="onModalClose"
          />

          <LibraryEditModal
            v-model="showLibraryEditModal"
            :library="libraryModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.multiselect-clear {
  padding: 0 0 0 var(--ms-px, 0.875rem) !important;
}
.multiselect-caret {
  margin: 0 0 0 var(--ms-px, 0.875rem) !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
