<script setup>
import useLibraries from "../../use/useLibraries"
import { computed, onUpdated, defineProps, ref, defineEmits } from "vue"

import store from "../../state/store"
const uploadComplete = computed(() => store.state.uploadComplete)

const props = defineProps({
  modelValue: Boolean,
  library: {
    required: true,
    type: Object,
  },
})

const libraryData = ref({
  id: props.library.id,
  name: props.library.name,
  book_image: props.library.book_image,
})

const emit = defineEmits(["update:modelValue", "close"])

const {
  show,
  loading,
  errors,
  frontErrorMessage,
  fileSelected,
  fileName,
  fileTotalSize,
  progress,
  onBookFileChange,
  onSubmit,
  closeModal,
} = useLibraries(props, emit, libraryData)

onUpdated(() => {
  libraryData.value = {
    id: props.library.id,
    name: props.library.name,
    book_image: props.library.book_image,
  }
})

const onBookImageChange = (event) => {
  libraryData.value.book_image = event.target.files[0]
}
</script>

<template>
  <!-- edit library modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="ویرایش کتاب"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="updateform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="mb-3">
            <label for="bookName" class="form-label">عنوان کتاب</label>
            <input
              type="text"
              id="bookName"
              class="form-control"
              placeholder="عنوان کتاب"
              v-model="libraryData.name"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.name">
                <span>{{ errors.name[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="BookImage" class="form-label">تصویر کتاب</label>
            <input
              class="form-control"
              type="file"
              id="BookImage"
              @change="onBookImageChange"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.book_image">
                <span>{{ errors.book_image[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <img
              class="img-thumbnail"
              :alt="libraryData.name"
              width="75"
              :src="libraryData.book_image"
              data-holder-rendered="true"
            />
          </div>

          <div class="mb-3">
            <label for="bookFile" class="form-label">فایل کتاب (pdf)</label>
            <input
              class="form-control"
              type="file"
              id="bookFile"
              @change="onBookFileChange"
              :disabled="uploadComplete"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.file">
                <span>{{ errors.file[0] }}</span>
              </div>
            </div>

            <div class="invalid-feedback" v-if="frontErrorMessage">
              <span>{{ frontErrorMessage }}</span>
            </div>

            <div v-if="fileSelected" class="my-3 overflow-hidden">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <h6 class="mb-0">
                    {{ fileName }}
                  </h6>
                </div>
                <div class="flex-shrink-0">
                  <h6 class="mb-0">{{ fileTotalSize }}</h6>
                </div>
              </div>

              <b-progress
                v-if="progress == 100 && uploadComplete"
                striped
                animated
                :value="progress"
                :max="100"
                variant="success"
                class="progress-xl"
                show-value
              ></b-progress>

              <b-progress
                v-else
                striped
                animated
                :value="progress"
                :max="100"
                variant="primary"
                class="progress-xl"
                show-value
              ></b-progress>
            </div>
          </div>
          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">ویرایش کتاب</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<style scoped>

</style>
