<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import usePrayers from "../../use/usePrayers"
import useDebouncedFunction from "../../use/useDebouncedFunction"
import TableHeaderCell from "@/components/table/TableHeaderCell.vue"

const prayers = computed(() => store.state.prayers)

const categoriesWithPrayers = computed(() => store.state.categoriesWithPrayers)

const {
  search,
  createdAt,
  categoryId,
  perPage,
  sortDirection,
  getPrayers,
  sortPrayers,
  deletePrayer,
} = usePrayers()

const sortField = ref("created_at")

const { handleInput } = useDebouncedFunction(sortPrayers)

const emit = defineEmits(["clickEdit", "clickView"])

onMounted(() => {
  getPrayers()
  store.dispatch("getCategoriesWithPrayers")
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }

  getPrayers(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editPrayer = (prayer) => {
  emit("clickEdit", prayer)
}

const viewPrayer = (prayer) => {
  emit("clickView", prayer)
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="3">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="جستجوی دعا عنوان و...."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="9">
        <b-row class="g-3">
          <b-col sm="5">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  فیلتر بر اساس تاریخ ثبت:
                </span>
              </div>
              <input
                type="date"
                @change="getPrayers(null)"
                v-model="createdAt"
                class="form-control"
              /></div
          ></b-col>

          <b-col sm="4">
            <select
              @change="getPrayers(null)"
              v-model="categoryId"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="">انتخاب دسته بندی</option>
              <option
                v-for="category in categoriesWithPrayers.data"
                :value="category.id"
                :key="category.id"
              >
                {{ category.title }}
              </option>
            </select>
          </b-col>

          <b-col sm="3">
            <select
              @change="getPrayers(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف</option>
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="prayerTable">
          <thead class="table-light text-muted">
            <tr>
              <TableHeaderCell
                field="title"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortPrayers('title')"
              >
                عنوان دعا
              </TableHeaderCell>
              <TableHeaderCell
                field="prayer_category_id "
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortPrayers('prayer_category_id ')"
              >
                عنوان دسته بندی
              </TableHeaderCell>
              <TableHeaderCell
                field="created_at"
                :sort-field="sortField"
                :sort-direction="sortDirection"
                @click="sortPrayers('created_at')"
              >
                تاریخ ثبت دعا
              </TableHeaderCell>

              <th data-sort="action">عملیات</th>
            </tr>
          </thead>

          <tbody class="list" v-if="prayers.loading || !prayers.data.length">
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="prayers.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ دعایی وجود ندارد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(prayer, index) of prayers.data" :key="index">
              <td class="title">
                {{ prayer.title }}
              </td>
              <td class="prayer-category-title">
                {{ prayer.category_title }}
              </td>
              <td class="date">
                {{ prayer.created_at }}
              </td>

              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item view"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="مشاهده دعا"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block view-item-btn"
                      @click="viewPrayer(prayer)"
                    >
                      <i class="ri-eye-fill fs-16"></i>
                    </BLink>
                  </li>
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="ویرایش دعا"
                  >
                    <BLink
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editPrayer(prayer)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </BLink>
                  </li>

                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="حذف دعا"
                  >
                    <BLink
                      variant="text-danger"
                      class="d-inline-block remove-item-btn"
                      @click="deletePrayer(prayer)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </BLink>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="prayers.total > prayers.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of prayers.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
