<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import TagModal from "./TagModal.vue"
import TagEditModal from "./TagEditModal.vue"
import TagsTable from "./TagsTable.vue"

const title = "مدیریت برچسب ها"
const pageTitle = "داشبورد"

const DEFAULT_TAG = {
  id: "",
  tag_name: "",
}

const tagModel = ref({ ...DEFAULT_TAG })
const showTagModal = ref(false)
const showTagEditModal = ref(false)

const showAddNewModal = () => {
  showTagModal.value = true
}

const showEditModal = () => {
  showTagEditModal.value = true
}

function editTag(tag) {
  store.dispatch("getTag", tag.id).then(({ data }) => {
    tagModel.value = data.data
    showEditModal()
  })
}

const onModalClose = () => {
  tagModel.value = { ...DEFAULT_TAG }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="tagList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست برچسب ها</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> افزودن برچسب
                    جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- tags table  -->

          <TagsTable @clickEdit="editTag" />

          <!-- add and edit tag modal  -->

          <TagModal
            v-model="showTagModal"
            :tag="tagModel"
            @close="onModalClose"
          />

          <TagEditModal
            v-model="showTagEditModal"
            :tag="tagModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.multiselect-clear {
  padding: 0 0 0 var(--ms-px, 0.875rem) !important;
}
.multiselect-caret {
  margin: 0 0 0 var(--ms-px, 0.875rem) !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
