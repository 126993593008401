import { ref } from "vue";
import store from "../state/store"


export default function useMessages() {

    const search = ref("")
    const unreadMessages = ref("")
    const perPage = ref(10)
    const sortField = ref("updated_at")
    const sortDirection = ref("desc")
    const loading = ref(false)
    const errors = ref({})

    const getMessages = (url = null) => {
        store.dispatch("getMessages", {
            url,
            search: search.value,
            unread_messages: unreadMessages.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    return {
        search,
        unreadMessages,
        perPage,
        loading,
        errors,
        getMessages,
    }
}