<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import usePrayers from "../../use/usePrayers"

const props = defineProps({
  prayer: {
    required: true,
    type: Object,
  },
})

const prayerData = ref({
  id: props.prayer.id,
  prayer_category_id: props.prayer.prayer_category_id,
  category_title: props.prayer.category_title,
  title: props.prayer.title,
  text: props.prayer.text,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, closeModal } = usePrayers(props, emit, prayerData)

onUpdated(() => {
  prayerData.value = {
    id: props.prayer.id,
    prayer_category_id: props.prayer.prayer_category_id,
    category_title: props.prayer.category_title,
    title: props.prayer.title,
    text: props.prayer.text,
  }
})
</script>

<template>
  <!-- view prayer modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="اطلاعات دعا"
    class="v-modal-custom"
    scrollable
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">عنوان دعا: </strong> {{ prayerData.title }}
      </h6>
      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">عنوان دسته بندی: </strong>
        {{ prayerData.category_title }}
      </h6>

      <h6 class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">متن دعا: </strong>
      </h6>
      <div class="py-2 px-3" v-html="prayerData.text"></div>
    </b-overlay>
    <div class="modal-footer v-modal-footer">
      <BButton type="button" variant="light" @click="closeModal">بستن </BButton>
    </div>
  </BModal>
</template>
