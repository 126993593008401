<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import CommentEditModal from "./CommentEditModal.vue"
import CommentsTable from "./CommentsTable.vue"

const title = "مدیریت نظرات"
const pageTitle = "داشبورد"

const DEFAULT_COMMENT = {
  id: "",
  comment: "",
  status: "",
}

const commentModel = ref({ ...DEFAULT_COMMENT })
const showCommentEditModal = ref(false)

const showEditModal = () => {
  showCommentEditModal.value = true
}

const editComment = (comm) => {
  commentModel.value = comm
  showEditModal()
}

const onModalClose = () => {
  commentModel.value = { ...DEFAULT_COMMENT }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="commentList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست نظرات</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2"></div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- comments table  -->

          <CommentsTable @clickEdit="editComment" />

          <!-- edit comment modal  -->

          <CommentEditModal
            v-model="showCommentEditModal"
            :commentProps="commentModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
