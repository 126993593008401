<script setup>
import {
  computed,
  ref,
  onUpdated,
  onMounted,
  defineProps,
  defineEmits,
} from "vue"
import useBlogs from "../../use/useBlogs"

import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"

import Editor from "ckeditor5-custom-build/build/ckeditor"
import CKEditor from "@ckeditor/ckeditor5-vue"

import store from "../../state/store"
const uploadComplete = computed(() => store.state.uploadComplete)

const props = defineProps({
  modelValue: Boolean,
  blog: {
    required: true,
    type: Object,
  },
  allTags: {
    required: true,
    type: Object,
  },
})

const blogData = ref({
  id: props.blog.id,
  title: props.blog.title,
  text: props.blog.text,
  post_type: props.blog.post_type,
  blog_image: props.blog.blog_image,
})

const emit = defineEmits(["update:modelValue", "close"])

const {
  show,
  loading,
  errors,
  selectedTags,
  frontErrorMessage,
  fileSelected,
  fileName,
  fileTotalSize,
  progress,
  onBlogFileChange,
  onSubmit,
  closeModal,
} = useBlogs(props, emit, blogData)

onUpdated(() => {
  blogData.value = {
    id: props.blog.id,
    title: props.blog.title,
    text: props.blog.text,
    post_type: props.blog.post_type,
    blog_image: props.blog.blog_image,
  }

  if (props.blog.tags) {
    selectedTags.value = props.blog.tags.map((e) => e.id)
  }
})

onMounted(() => {
  store.dispatch("getAllTags")
})

/**
 *! start editor
 */

const editorConfig = {
  language: "fa",
  ckfinder: {
    options: {
      language: "fa",
    },
  },
}

const editor = Editor
const ckeditor = CKEditor.component

/**
 *! end editor
 */

const onBlogImageChange = (event) => {
  blogData.value.blog_image = event.target.files[0]
}
</script>

<template>
  <!-- add and edit blog modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="ویرایش پست"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="mb-3">
            <label for="blogtitle" class="form-label"> عنوان پست</label>
            <input
              type="text"
              id="blogtitle"
              class="form-control"
              placeholder="عنوان پست"
              v-model="blogData.title"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.title">
                <span>{{ errors.title[0] }}</span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="blogtext" class="form-label">متن پست</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="blogData.text"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.text">
                <span>{{ errors.text[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="BlogImage" class="form-label">تصویر</label>
            <input
              class="form-control"
              type="file"
              id="BlogImage"
              @change="onBlogImageChange"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.blog_image">
                <span>{{ errors.blog_image[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <img
              class="img-thumbnail"
              :alt="blogData.name"
              width="75"
              :src="blogData.blog_image"
              data-holder-rendered="true"
            />
          </div>

          <div class="mb-3">
            <label for="name" class="form-label">برچسب</label>
            <Multiselect
              class="form-control"
              v-model="selectedTags"
              mode="tags"
              :searchable="true"
              :create-option="false"
              :close-on-select="false"
              :options="allTags"
              placeholder="یک یا چند برچسب انتخاب کنید"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.tagId">
                <span>{{ errors.tagId[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="posttype" class="form-label">نوع</label>
            <select class="form-select mb-3" v-model="blogData.post_type">
              <option value="">انتخاب نوع پست</option>
              <option value="article">مقاله متنی</option>
              <option value="video">ویدیو</option>
            </select>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.post_type">
                <span>{{ errors.post_type[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3" v-if="blogData.post_type === 'video'">
            <label for="blogFile" class="form-label">فایل ویدیو</label>
            <input
              class="form-control"
              type="file"
              id="blogFile"
              @change="onBlogFileChange"
              :disabled="uploadComplete"
            />

            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.file">
                <span>{{ errors.file[0] }}</span>
              </div>
            </div>

            <div class="invalid-feedback" v-if="frontErrorMessage">
              <span>{{ frontErrorMessage }}</span>
            </div>

            <div v-if="fileSelected" class="my-3 overflow-hidden">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <h6 class="mb-0">
                    {{ fileName }}
                  </h6>
                </div>
                <div class="flex-shrink-0">
                  <h6 class="mb-0">{{ fileTotalSize }}</h6>
                </div>
              </div>

              <b-progress
                v-if="progress == 100 && uploadComplete"
                striped
                animated
                :value="progress"
                :max="100"
                variant="success"
                class="progress-xl"
                show-value
              ></b-progress>

              <b-progress
                v-else
                striped
                animated
                :value="progress"
                :max="100"
                variant="primary"
                class="progress-xl"
                show-value
              ></b-progress>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">ویرایش پست</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
