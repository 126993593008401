<script>
import { layoutComputed } from "@/state/helpers"

import Vertical from "./vertical"
import Horizontal from "./horizontal"
import TwoColumns from "./twocolumn"

export default {
  components: {
    Vertical,
    Horizontal,
    TwoColumns,
  },
  data() {
    return {}
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
    document.querySelector("html").setAttribute("dir", "rtl")
  },
}
</script>

<template>
  <div>
    <Vertical
      v-if="layoutType === 'vertical' || layoutType === 'semibox'"
      :layout="layoutType"
    >
      <slot />
    </Vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>

    <TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
      <slot />
    </TwoColumns>
  </div>
</template>
