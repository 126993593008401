<script setup>
import { computed, ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import LiturgyModal from "./LiturgyModal.vue"
import LiturgyEditModal from "./LiturgyEditModal.vue"
import LiturgiesTable from "./LiturgiesTable.vue"
import LiturgyViewModal from "./LiturgyViewModal.vue"

const title = "مدیریت نیایش روز"
const pageTitle = "داشبورد"

const DEFAULT_LITURGY = {
  id: "",
  liturgy_category_id: "",
  category_title: "",
  text: "",
  date: "",
}

const allLiturgyCategories = computed(() => store.state.allLiturgyCategories)

const liturgyModel = ref({ ...DEFAULT_LITURGY })
const showLiturgyModal = ref(false)
const showLiturgyEditModal = ref(false)
const showLiturgyViewModal = ref(false)

const showAddNewModal = () => {
  showLiturgyModal.value = true
}

const showEditModal = () => {
  showLiturgyEditModal.value = true
}

const showViewModal = () => {
  showLiturgyViewModal.value = true
}

function editLiturgy(liturgy) {
  store.dispatch("getLiturgy", liturgy.id).then(({ data }) => {
    liturgyModel.value = data.data
    showEditModal()
  })
}

const viewLiturgy = (liturgy) => {
  store.dispatch("getLiturgy", liturgy.id).then(({ data }) => {
    liturgyModel.value = data.data
    showViewModal()
  })
}

const onModalClose = () => {
  liturgyModel.value = { ...DEFAULT_LITURGY }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="liturgyList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست نیایش های روز</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> افزودن نیایش
                    روز جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- liturgies table  -->

          <LiturgiesTable @clickEdit="editLiturgy" @clickView="viewLiturgy" />

          <!-- add and edit liturgy modal  -->

          <LiturgyModal
            v-model="showLiturgyModal"
            :liturgy="liturgyModel"
            :allLiturgyCategories="allLiturgyCategories"
            @close="onModalClose"
          />

          <LiturgyViewModal
            v-model="showLiturgyViewModal"
            :liturgy="liturgyModel"
            @close="onModalClose"
          />

          <LiturgyEditModal
            v-model="showLiturgyEditModal"
            :liturgy="liturgyModel"
            :allLiturgyCategories="allLiturgyCategories"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.multiselect-clear {
  padding: 0 0 0 var(--ms-px, 0.875rem) !important;
}
.multiselect-caret {
  margin: 0 0 0 var(--ms-px, 0.875rem) !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
