<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useBlogs from "../../use/useBlogs"

const props = defineProps({
  blog: {
    required: true,
    type: Object,
  },
})

const blogData = ref({
  id: props.blog.id,
  title: props.blog.title,
  text: props.blog.text,
  post_type: props.blog.post_type,
  file_name: props.blog.file_name,
  blog_image: props.blog.blog_image,
  tags: props.blog.tags,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, closeModal } = useBlogs(props, emit, blogData)

onUpdated(() => {
  blogData.value = {
    id: props.blog.id,
    title: props.blog.title,
    text: props.blog.text,
    post_type: props.blog.post_type,
    file_name: props.blog.file_name,
    blog_image: props.blog.blog_image,
    tags: props.blog.tags,
  }
})
</script>

<template>
  <!-- view blog modal  -->
  <BModal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="جزئیات پست"
    class="v-modal-custom"
    scrollable
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <!-- Thumbnails Images -->
      <b-img
        v-if="blogData.blog_image"
        class="img-thumbnail"
        :alt="blogData.title"
        width="200"
        :src="blogData.blog_image"
        data-holder-rendered="true"
      ></b-img>
      <h6 v-if="blogData.title" class="fs-15 mt-4">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">عنوان پست: </strong> {{ blogData.title }}
      </h6>

      <h6 v-if="blogData.text" class="fs-15 mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">متن پست: </strong>
      </h6>
      <div v-if="blogData.text" class="py-2 px-3" v-html="blogData.text"></div>
      <div v-if="blogData.post_type" class="mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">نوع پست: </strong>
        <span class="badge border border-secondary text-secondary">{{
          blogData.post_type === "video" ? "ویدیو" : "مقاله"
        }}</span>
      </div>
      <div v-if="blogData.file_name" class="mt-3">
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong class="mx-1">نام فایل: </strong>
        <span>{{ blogData.file_name.split("_").pop() }}</span>
      </div>
      <div
        v-if="blogData.tags && blogData.tags.length !== 0"
        class="d-flex flex-wrap gap-1 mt-3"
      >
        <i class="ri-checkbox-circle-fill text-success"></i>
        <strong>برچسب ها: </strong>
        <span
          class="badge border border-secondary text-secondary"
          v-for="(tag, index) of blogData.tags"
          :key="index"
          >{{ tag.tag_name }}</span
        >
      </div>
    </b-overlay>
    <div class="modal-footer v-modal-footer">
      <BButton type="button" variant="light" @click="closeModal">بستن </BButton>
    </div>
  </BModal>
</template>
