<script setup>
import { computed, onMounted, ref, defineEmits } from "vue"

import Lottie from "@/components/widgets/lottie.vue"
import animationData from "@/components/widgets/msoeawqm.json"

import store from "../../state/store"
import useLibraries from "../../use/useLibraries"
import useDebouncedFunction from "../../use/useDebouncedFunction"

const libraries = computed(() => store.state.libraries)

const { search, perPage, getLibraries, deleteLibrary } = useLibraries()
const { handleInput } = useDebouncedFunction(getLibraries)

const emit = defineEmits(["clickEdit"])

onMounted(() => {
  getLibraries()
})

function getForPage(ev, link) {
  ev.preventDefault()
  if (!link.url || link.active) {
    return
  }
  getLibraries(link.url)
}

const defaultOptions = ref({
  animationData: animationData,
})

const editLibrary = (library) => {
  emit("clickEdit", library)
}
</script>

<template>
  <b-card-body class="border-bottom-dashed border-bottom">
    <b-row class="g-3">
      <b-col xl="7">
        <div class="search-box">
          <input
            v-model="search"
            @input="handleInput"
            type="text"
            class="form-control search"
            placeholder="جستجوی عنوان کتاب ..."
          />
          <i class="ri-search-line search-icon"></i>
        </div>
      </b-col>

      <b-col xl="5">
        <b-row class="g-3">
          <b-col sm="6"> </b-col>

          <b-col sm="6">
            <select
              @change="getLibraries(null)"
              v-model="perPage"
              class="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="" selected>نمایش تعداد ردیف</option>
              <option value="2">2</option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card-body>
  <b-card-body>
    <div>
      <div class="table-responsive table-card mb-1">
        <table class="table align-middle" id="libraryTable">
          <thead class="table-light text-muted">
            <tr>
              <th class="sort" data-sort="book_image">تصویر</th>
              <th class="sort" data-sort="name">عنوان کتاب</th>
              <th class="sort" data-sort="file-name">نام فایل</th>
              <th class="sort" data-sort="size">سایز</th>
              <th class="sort" data-sort="created-at">تاریخ افزودن</th>
              <th class="sort" data-sort="action">عملیات</th>
            </tr>
          </thead>

          <tbody
            class="list"
            v-if="libraries.loading || !libraries.data.length"
          >
            <tr>
              <td colspan="8">
                <div class="text-center">
                  <b-spinner
                    v-if="libraries.loading"
                    variant="dark"
                    style="width: 3rem; height: 3rem"
                  ></b-spinner>
                  <div v-else>
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">هیچ کتابی وجود ندارد!</h5>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody class="list" v-else>
            <tr v-for="(library, index) of libraries.data" :key="index">
              <td class="book_image">
                <div class="avatar-sm bg-light rounded p-1">
                  <img
                    :src="library.book_image"
                    :alt="library.name"
                    class="img-fluid d-block"
                  />
                </div>
              </td>
              <td class="name">{{ library.name }}</td>
              <td class="file-name" dir="ltr">{{ library.file_name }}</td>
              <td class="size" dir="ltr">{{ library.size }}</td>
              <td class="created-at" dir="ltr">{{ library.created_at }}</td>
              <td>
                <ul class="list-inline hstack gap-2 mb-0">
                  <li
                    class="list-inline-item edit"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="ویرایش کتاب"
                  >
                    <b-link
                      variant="text-primary"
                      class="d-inline-block edit-item-btn"
                      @click="editLibrary(library)"
                    >
                      <i class="ri-pencil-fill fs-16"></i>
                    </b-link>
                  </li>
                  <li
                    class="list-inline-item"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="حذف کتاب"
                  >
                    <b-link
                      class="text-danger d-inline-block remove-item-btn"
                      @click="deleteLibrary(library)"
                    >
                      <i class="ri-delete-bin-5-fill fs-16"></i>
                    </b-link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        class="d-flex justify-content-end"
        v-if="libraries.total > libraries.limit"
      >
        <div class="pagination-wrap hstack gap-2" style="display: flex">
          <ul class="pagination listjs-pagination mb-0">
            <li
              v-for="(link, i) of libraries.links"
              :key="i"
              class="page-item"
              :class="{
                disabled: !link.url,
                active: link.active || !link.url,
              }"
            >
              <span
                class="page-link"
                v-if="link.active"
                v-html="link.label"
              ></span>

              <a
                v-else
                href="#"
                @click="getForPage($event, link)"
                class="page-link"
                v-html="link.label"
              >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-card-body>
</template>
