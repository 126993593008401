import debounce from 'lodash.debounce';

export default function useDebouncedFunction(fn, delay = 1000) {
    const debouncedFn = debounce(() => {
        fn();
    }, delay);

    const handleInput = () => {
        debouncedFn();
    };

    return {
        handleInput,
    };
}
