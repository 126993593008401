import axiosClient from "../../axios";


export async function login({ commit }, data) {
    return await axiosClient.post('/login', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            commit('setToken', data.token)
            return data;
        })
}

export async function logout({ commit }) {
    return await axiosClient.post('/logout')
        .then((response) => {
            commit('setToken', null)
            commit('setUser', null);

            return response
        })
}

export async function deleteAccount({ commit }, data) {
    return await axiosClient.post('/account/delete', data)
        .then(({ data }) => {
            return data;
        })
}