<script setup>
import useTags from "../../use/useTags"
import { onUpdated, defineProps, ref, defineEmits } from "vue"

const props = defineProps({
  modelValue: Boolean,
  tag: {
    required: true,
    type: Object,
  },
})

const tagData = ref({
  id: props.tag.id,
  tag_name: props.tag.title,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useTags(
  props,
  emit,
  tagData
)

onUpdated(() => {
  tagData.value = {
    id: props.tag.id,
    tag_name: props.tag.title,
  }
})

</script>

<template>
  <!-- edit tag modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="ویرایش برچسب"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
          enctype="multipart/form-data"
        >
          <div class="mb-3">
            <label for="title" class="form-label">عنوان برچسب</label>
            <input
              type="text"
              id="title"
              class="form-control"
              placeholder="عنوان برچسب"
              v-model="tagData.tag_name"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.tag_name">
                <span>{{ errors.tag_name[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">ویرایش برچسب</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
