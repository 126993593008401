import axiosClient from "../../axios";

/**
 * Users functions
 */
export async function createUser({ commit }, user) {
    return await axiosClient.post('/admin/users', user)
}

export async function getUsers({ commit, state }, { url = null, search = '', status, created_at, per_page, sort_field, sort_direction } = {}) {
    commit('setUsers', [true])
    url = url || "/admin/users"
    const params = {
        per_page: state.users.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, status, created_at, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setUsers', [false, response.data])
        })
        .catch(() => {
            commit('setUsers', [false])
        })
}

export async function getUser({ commit }, id) {
    return await axiosClient.get(`/admin/users/${id}`)
}

export async function updateUser({ commit }, user) {
    return await axiosClient.put(`/admin/users/${user.id}`, user);
}

export async function changePasswordUser({ commit }, user) {
    return await axiosClient.put(`/admin/users/change-password/${user.id}`, user);
}

/**
 * Saints functions
 */
export async function createSaint({ commit }, saint) {
    return await axiosClient.post('/admin/saints', saint)
}

export async function getSaints({ commit, state }, { url = null, search = '', date, per_page, sort_field, sort_direction } = {}) {
    commit('setSaints', [true])
    url = url || "/admin/saints"
    const params = {
        per_page: state.saints.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, date, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setSaints', [false, response.data])
        })
        .catch(() => {
            commit('setSaints', [false])
        })
}

export async function getSaint({ commit }, id) {
    return await axiosClient.get(`/admin/saints/${id}`)
}

export async function updateSaint({ commit }, saint) {
    return await axiosClient.put(`/admin/saints/${saint.id}`, saint);
}

export function deleteSaint({ commit }, saint) {
    return axiosClient.delete(`/admin/saints/${saint.id}`)
}

/**
 * Liturgies functions
 */
export async function createLiturgy({ commit }, liturgy) {
    return await axiosClient.post('/admin/liturgies', liturgy)
}

export async function getLiturgies({ commit, state }, { url = null, categoryId = '', date, per_page, sort_field, sort_direction } = {}) {
    commit('setLiturgies', [true])
    url = url || "/admin/liturgies"
    const params = {
        per_page: state.liturgies.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            categoryId, date, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setLiturgies', [false, response.data])
        })
        .catch(() => {
            commit('setLiturgies', [false])
        })
}

export async function getLiturgy({ commit }, id) {
    return await axiosClient.get(`/admin/liturgies/${id}`)
}

export async function updateLiturgy({ commit }, liturgy) {
    return await axiosClient.put(`/admin/liturgies/${liturgy.id}`, liturgy);
}

export function deleteLiturgy({ commit }, liturgy) {
    return axiosClient.delete(`/admin/liturgies/${liturgy.id}`)
}

export async function getAllLiturgyCategories({ commit }) {
    commit('setAllLiturgyCategories', [true])

    return await axiosClient.get("/admin/liturgies/categories/all")
        .then((response) => {
            commit('setAllLiturgyCategories', [false, response.data])
        })
        .catch(() => {
            commit('setAllLiturgyCategories', [false])
        })
}

export async function getCategoriesWithLiturgies({ commit }) {
    commit('setCategoriesWithLiturgies', [true])

    return await axiosClient.get("/admin/liturgies/categories/with-liturgies")
        .then((response) => {
            commit('setCategoriesWithLiturgies', [false, response.data])
        })
        .catch(() => {
            commit('setCategoriesWithLiturgies', [false])
        })
}

/**
 * Masses functions
 */
export async function createMass({ commit }, mass) {
    return await axiosClient.post('/admin/masses', mass)
}

export async function getMasses({ commit, state }, { url = null, date, per_page, sort_field, sort_direction } = {}) {
    commit('setMasses', [true])
    url = url || "/admin/masses"
    const params = {
        per_page: state.masses.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            date, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setMasses', [false, response.data])
        })
        .catch(() => {
            commit('setMasses', [false])
        })
}

export async function getMass({ commit }, id) {
    return await axiosClient.get(`/admin/masses/${id}`)
}

export async function updateMass({ commit }, mass) {
    return await axiosClient.put(`/admin/masses/${mass.id}`, mass);
}

export function deleteMass({ commit }, mass) {
    return axiosClient.delete(`/admin/masses/${mass.id}`)
}

/**
 * Prayers functions
 */
export async function createPrayer({ commit }, prayer) {
    return await axiosClient.post('/admin/prayers', prayer)
}

export async function getPrayers({ commit, state }, { url = null, search = '', categoryId = '', createdAt, per_page, sort_field, sort_direction } = {}) {
    commit('setPrayers', [true])
    url = url || "/admin/prayers"
    const params = {
        per_page: state.prayers.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, categoryId, createdAt, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setPrayers', [false, response.data])
        })
        .catch(() => {
            commit('setPrayers', [false])
        })
}

export async function getPrayer({ commit }, id) {
    return await axiosClient.get(`/admin/prayers/${id}`)
}

export async function updatePrayer({ commit }, prayer) {
    return await axiosClient.put(`/admin/prayers/${prayer.id}`, prayer);
}

export function deletePrayer({ commit }, prayer) {
    return axiosClient.delete(`/admin/prayers/${prayer.id}`)
}

export async function getAllPrayerCategories({ commit }) {
    commit('setAllPrayerCategories', [true])

    return await axiosClient.get("/admin/prayers/categories/all")
        .then((response) => {
            commit('setAllPrayerCategories', [false, response.data])
        })
        .catch(() => {
            commit('setAllPrayerCategories', [false])
        })
}

export async function getCategoriesWithPrayers({ commit }) {
    commit('setCategoriesWithPrayers', [true])

    return await axiosClient.get("/admin/prayers/categories/with-prayers")
        .then((response) => {
            commit('setCategoriesWithPrayers', [false, response.data])
        })
        .catch(() => {
            commit('setCategoriesWithPrayers', [false])
        })
}

/**
 * Libraries functions
 */
export async function getLibraries({ commit, state }, { url = null, search = '', per_page, sort_field, sort_direction } = {}) {
    commit('setLibraries', [true])
    url = url || "/admin/libraries"
    const params = {
        per_page: state.libraries.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setLibraries', [false, response.data])
        })
        .catch(() => {
            commit('setLibraries', [false])
        })
}

export async function getLibrary({ commit }, id) {
    return await axiosClient.get(`/admin/libraries/${id}`)
}

export function updateLibrary({ commit }, library) {

    const id = library.id
    const form = new FormData();
    form.append('id', library.id);
    form.append('name', library.name || '');

    if (library.book_image instanceof File) {
        form.append('book_image', library.book_image);
    } else {
        form.append('book_image', '');
    }

    form.append('_method', 'PUT');
    library = form;

    return axiosClient.post(`/admin/libraries/${id}`, library)
}

export function deleteLibrary({ commit }, library) {
    return axiosClient.delete(`/admin/libraries/${library.id}`)
}

export async function uploadBookFile({ commit }, { formData, uploaded }) {
    return await axiosClient.post('/admin/libraries/upload/book', formData.value, {
        headers: {
            'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: (event) => {
            uploaded.value += event.loaded;
        }
    });
}

export async function uploadCompleteTrue({ commit }) {
    commit('setUploadComplete');
}

export async function uploadCompleteFalse({ commit }) {
    commit('setUploadCompleteFalse');
}

/**
 * Blogs functions
 */
export async function createBlog({ commit }, { blogData, tag_id }) {
    const form = new FormData();
    form.append('title', blogData.value.title || '');
    form.append('text', blogData.value.text || '');
    form.append('post_type', blogData.value.post_type || '');

    if (blogData.value.blog_image instanceof File) {
        form.append('blog_image', blogData.value.blog_image);
    } else {
        form.append('blog_image', '');
    }

    for (let j = 0; j < tag_id.length; j++) {
        form.append('tagId[]', tag_id[j])
    }

    blogData = form;

    return await axiosClient.post('/admin/blogs', blogData)
}

export async function getBlogs({ commit, state }, { url = null, search = '', createdAt, postType, per_page, sort_field, sort_direction } = {}) {
    commit('setBlogs', [true])
    url = url || "/admin/blogs"
    const params = {
        per_page: state.blogs.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, createdAt, postType, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setBlogs', [false, response.data])
        })
        .catch(() => {
            commit('setBlogs', [false])
        })
}

export async function getBlog({ commit }, id) {
    return await axiosClient.get(`/admin/blogs/${id}`)
}

export async function updateBlog({ commit }, { blogData, tag_id }) {
    const id = blogData.value.id
    const form = new FormData();
    form.append('id', id);
    form.append('title', blogData.value.title || '');
    form.append('text', blogData.value.text || '');
    form.append('post_type', blogData.value.post_type || '');

    if (blogData.value.blog_image instanceof File) {
        form.append('blog_image', blogData.value.blog_image);
    } else {
        form.append('blog_image', '');
    }

    for (let j = 0; j < tag_id.length; j++) {
        form.append('tagId[]', tag_id[j])
    }

    form.append('_method', 'PUT');
    blogData = form;

    return axiosClient.post(`/admin/blogs/${id}`, blogData)
}

export function deleteBlog({ commit }, blog) {
    return axiosClient.delete(`/admin/blogs/${blog.id}`)
}

export async function getAllTags({ commit }) {
    commit('setAllTags', [true])

    return await axiosClient.get("/admin/tags/all")
        .then((response) => {
            commit('setAllTags', [false, response.data])
        })
        .catch(() => {
            commit('setAllTags', [false])
        })
}

export async function uploadBlogFile({ commit }, { formData, uploaded }) {
    return await axiosClient.post('/admin/blogs/upload/video', formData.value, {
        headers: {
            'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: (event) => {
            uploaded.value += event.loaded;
        }
    });
}

/**
 * Tags functions
 */
export async function createTag({ commit }, tag) {

    const form = new FormData();
    form.append('tag_name', tag.tag_name || '');

    tag = form;
    return await axiosClient.post('/admin/tags', tag)
}

export async function getTags({ commit, state }, { url = null, search = '', per_page, sort_field, sort_direction } = {}) {
    commit('setTags', [true])
    url = url || "/admin/tags"
    const params = {
        per_page: state.tags.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setTags', [false, response.data])
        })
        .catch(() => {
            commit('setTags', [false])
        })
}

export async function getTag({ commit }, id) {
    return await axiosClient.get(`/admin/tags/${id}`)
}

export function updateTag({ commit }, tag) {

    const id = tag.id
    const form = new FormData();
    form.append('id', tag.id);
    form.append('tag_name', tag.tag_name || '');


    form.append('_method', 'PUT');
    tag = form;

    return axiosClient.post(`/admin/tags/${id}`, tag)
}

/**
 * Comments functions
 */
export async function getComments({ commit, state }, { url = null, search = '', status, per_page, sort_field, sort_direction } = {}) {
    commit('setComments', [true])
    url = url || "/admin/comments"
    const params = {
        per_page: state.comments.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, status, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setComments', [false, response.data])
        })
        .catch(() => {
            commit('setComments', [false])
        })
}

export async function getComment({ commit }, id) {
    return await axiosClient.get(`/admin/comments/${id}`)
}

export async function updateComment({ commit }, comment) {
    return await axiosClient.put(`/admin/comments/${comment.id}`, comment);
}

/* export async function getCommentCount({ commit }) {
    return await axiosClient.get('/admin/comments/count')
} */

export async function getCommentCount({ commit }) {
    return await axiosClient.get('/admin/comments/count')
        .then((response) => {
            commit('setCommentCount', [response.data.commentCount])
        })
}

/**
 * Messages functions
 */
export async function getUserMessages({ commit }, userId) {
    return await axiosClient.get(`/admin/user-messages/${userId}`)
}

export async function replyToMessage({ commit }, replyMessage) {
    return await axiosClient.post('/admin/reply-message', replyMessage)
}

export async function getMessages({ commit, state }, { url = null, search = '', unread_messages, per_page, sort_field, sort_direction } = {}) {
    commit('setMessages', [true])
    url = url || "/admin/users-messages"
    const params = {
        per_page: state.messages.limit,
    }

    return await axiosClient.get(url, {
        params: {
            ...params,
            search, unread_messages, per_page, sort_field, sort_direction
        }
    })
        .then((response) => {
            commit('setMessages', [false, response.data])
        })
        .catch(() => {
            commit('setMessages', [false])
        })
}

export async function getMessageCount({ commit }) {
    return await axiosClient.get('/admin/messages/count')
        .then((response) => {
            commit('seMessageCount', [response.data.messageCount])
        })
}

/**
 * Profile functions
 */
export async function getCurrentUser({ commit }, data) {
    return await axiosClient.get('/admin/user/info', data)
        .then(({ data }) => {
            commit('setUser', data.user);
            return data;
        })
}

export function updateProfileInfo({ commit }, { user }) {

    const form = new FormData();

    form.append('name', user.value.name);
    form.append('email', user.value.email);

    form.append('_method', 'PUT');
    user = form;

    return axiosClient.post('/admin/profile/edit', user)
}

export async function changePassword({ commit }, passwordData) {
    return await axiosClient.put('/admin/change-password', passwordData);
}







