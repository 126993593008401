<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useMasses from "../../use/useMasses"

import Editor from "ckeditor5-custom-build/build/ckeditor"
import CKEditor from "@ckeditor/ckeditor5-vue"

const props = defineProps({
  mass: {
    required: true,
    type: Object,
  },
})

const massData = ref({
  id: props.mass.id,
  text: props.mass.text,
  date: props.mass.date,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useMasses(
  props,
  emit,
  massData
)

onUpdated(() => {
  massData.value = {
    id: props.mass.id,
    text: props.mass.text,
    date: props.mass.date,
  }
})

/**
 *! start editor
 */

const editorConfig = {
  language: "fa",
  ckfinder: {
    options: {
      language: "fa",
    },
  },
}

const editor = Editor
const ckeditor = CKEditor.component

/**
 *! end editor
 */
</script>

<template>
  <!-- add mass modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title="افزودن آیین قربانی جدید"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
    size="lg"
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="masstdate" class="form-label">تاریخ آیین قربانی</label>
            <input
              type="date"
              id="masstdate"
              class="form-control"
              placeholder="تاریخ آیین قربانی"
              v-model="massData.date"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.date">
                <span>{{ errors.date[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="masstext" class="form-label">متن آیین قربانی</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="massData.text"
            />
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.text">
                <span>{{ errors.text[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success">افزودن آیین قربانی</b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
