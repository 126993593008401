import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useSortData from './useSortData';
import useDelete from './useDelete';

export default function useSaints(props, emit, saintData) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const date = ref("")
    const perPage = ref(10)
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getSaints = (url = null) => {
        store.dispatch("getSaints", {
            url,
            search: search.value,
            date: date.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (saintData.value.id) {
            store.dispatch('updateSaint', saintData.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("قدیس با موفقیت به روز شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getSaints")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createSaint", saintData.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        console.log('Yes');
                        toast.success("قدیس مورد نظر با موفقیت اضافه شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getSaints")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    const { sortField, sortDirection, sortData } = useSortData(getSaints);

    const sortSaints = (field) => {
        sortData(field);
        getSaints();
    };

    const deleteSaint = (saint) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteSaint", saint),
            'قدیس مورد نظر حذف شد.',
            getSaints
        );

        confirmDelete();
    };


    return {
        show,
        search,
        date,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getSaints,
        onSubmit,
        closeModal,
        sortSaints,
        deleteSaint,
    }
}