import { ref, computed, watchEffect } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useDelete from './useDelete';


export default function useLibraries(props, emit, library) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const perPage = ref(10)
    const sortField = ref("updated_at")
    const sortDirection = ref("desc")
    const loading = ref(false)
    const errors = ref({})
    const frontErrorMessage = ref('');
    const libraryId = ref("")

    const fileSelected = ref(false);
    const file = ref(null);
    const chunks = ref([]);
    const uploaded = ref(0);

    const fileName = ref("null")
    const fileTotalSize = ref(null)


    const progress = computed(() => {
        if (!file.value || !file.value.size) return 0;

        const fileSize = file.value.size;
        const uploadedSize = uploaded.value;
        return (Math.floor((uploadedSize * 100) / fileSize) > 100) ? 100 : Math.floor((uploadedSize * 100) / fileSize);
    })

    const formData = computed(() => {
        const formData = new FormData();
        formData.set('is_last', chunks.value.length === 1);
        formData.set('file', chunks.value[0], `${file.value.name}.part`);

        if (chunks.value.length === 1) {
            formData.append('id', library.value.id || '');
            formData.append('file_size', file.value.size);
        }

        return formData;
    });

    const onBookFileChange = (event) => {
        file.value = event.target.files.item(0);
        const allowedTypes = ['application/pdf'];
        if (!file.value) return

        if (!allowedTypes.includes(file.value.type)) {
            frontErrorMessage.value = 'فرمت فایل نامعتبر است. لطفاً یک فایل PDF انتخاب کنید.';
            return;
        }

        fileName.value = file.value.name.length >= 30
            ? file.value.name.substring(0, 31) + "... ." + file.value.name.split(".").pop()
            : file.value.name

        fileSelected.value = true;
        fileTotalSize.value = (file.value.size < 1024) ? file.value.size + 'KB' : (file.value.size / (1024 * 1024)).toFixed(2) + 'MB';

        frontErrorMessage.value = '';

        createChunks();
    };

    const upload = () => {
        store.dispatch("uploadBookFile", { formData, uploaded })
            .then((response) => {

                if (response.data.uploaded === '100%') {
                    libraryId.value = response.data.id;
                    store
                        .dispatch("uploadCompleteTrue")
                }
                chunks.value.shift();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const createChunks = () => {
        const size = 2 * 1024 * 1024;
        const numChunks = Math.ceil(file.value.size / size);

        for (let i = 0; i < numChunks; i++) {
            chunks.value.push(file.value.slice(
                i * size, Math.min(i * size + size, file.value.size), file.value.type
            ));
        }
    };

    watchEffect(() => {
        if (chunks.value.length > 0) {
            upload();
        }
    });

    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getLibraries = (url = null) => {
        store.dispatch("getLibraries", {
            url,
            search: search.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (libraryId.value) {
            library.value.id = libraryId.value
        }
        if (library.value.id) {
            store.dispatch('updateLibrary', library.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("کتاب با موفقیت به روزرسانی شد!", {
                            timeout: 3000,
                        })
                        store
                            .dispatch("uploadCompleteFalse")
                        fileSelected.value = false;
                        store.dispatch("getLibraries")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })

        } else {
            console.error('Library ID not found!');
        }

    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        const imageInput = document.getElementById("BookImage")
        const fileInput = document.getElementById("bookFile")
        imageInput.value = ""
        fileInput.value = ""
        library.value.book_image = null
        frontErrorMessage.value = ""
        emit("close")
    }

    const deleteLibrary = (library) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deleteLibrary", library),
            'کتاب مورد نظر حذف شد.',
            getLibraries
        );

        confirmDelete();
    };

    return {
        show,
        search,
        perPage,
        loading,
        errors,
        frontErrorMessage,
        fileSelected,
        fileName,
        fileTotalSize,
        progress,
        getLibraries,
        onBookFileChange,
        onSubmit,
        closeModal,
        deleteLibrary,
    }
}