/**
 * Created by Iman on 12/05/2023.
 */

import axios from "axios";
import store from "./state/store";
import router from "./router/index.js";

const axiosClient = axios.create({
  /* baseURL: "http://127.0.0.1:8000/api/v1", */
  /* baseURL: "http://192.168.1.107/api/v1", */
  baseURL: "https://farsicatholicfaith.com/backend/api/v1",
})

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.user.token}`
  return config;
})

axiosClient.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    store.commit('setToken', null)
    router.push({ name: 'login' })
  }
  throw error;
})

export default axiosClient;
