export default {
    user: {
        loggedIn: localStorage.getItem('LOGGEDIN') ? true : false,
        isAdmin: localStorage.getItem('USERROLE') === 'Admin' ? true : false,
        token: localStorage.getItem('TOKEN') ? localStorage.getItem('TOKEN') : null,
        data: JSON.parse(localStorage.getItem('USER')) ? JSON.parse(localStorage.getItem('USER')) : {},
    },

    users: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    saints: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    liturgies: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    allLiturgyCategories: {
        loading: false,
        data: [],
    },

    categoriesWithLiturgies: {
        loading: false,
        data: [],
    },

    masses: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    prayers: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    allPrayerCategories: {
        loading: false,
        data: [],
    },

    categoriesWithPrayers: {
        loading: false,
        data: [],
    },

    libraries: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    uploadComplete: false,

    blogs: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    allTags: {
        loading: false,
        data: [],
    },

    tags: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    comments: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    commentCount: 0,

    messages: {
        loading: false,
        data: [],
        links: [],
        from: null,
        to: null,
        page: 1,
        limit: null,
        total: null
    },

    messageCount: 0,


}