export const state = {
    currentUser: localStorage.getItem('userid'),
}

export const mutations = {

}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {

}

