import dashboard from '../views/dashboard/admin/index.vue'
import users from '../views/users/index.vue'
import saints from '../views/saints/index.vue'
import liturgies from '../views/liturgies/index.vue'
import masses from '../views/masses/index.vue'
import prayers from '../views/prayers/index.vue'
import libraries from '../views/libraries/index.vue'
import blog from '../views/blog/index.vue'
import tags from '../views/tags/index.vue'
import comments from '../views/comments/index.vue'
import messages from '../views/messages/index.vue'
import userMessages from '../views/users/userMessages.vue'
import profile from '../views/settings/Profile.vue'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: {
      title: "پنل مدیریت",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: users,
    meta: {
      title: "مدیریت کاربران",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/saints',
    name: 'saints',
    component: saints,
    meta: {
      title: "مدیریت قدیس های روزانه",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/liturgies',
    name: 'liturgies',
    component: liturgies,
    meta: {
      title: "مدیریت نیایش روز",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/masses',
    name: 'masses',
    component: masses,
    meta: {
      title: "مدیریت آیین قربانی",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/prayers',
    name: 'prayers',
    component: prayers,
    meta: {
      title: "مدیریت دعاها",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/libraries',
    name: 'libraries',
    component: libraries,
    meta: {
      title: "مدیریت کتابخانه",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/blog',
    name: 'blog',
    component: blog,
    meta: {
      title: "بخش وبلاگ",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/tags',
    name: 'tags',
    component: tags,
    meta: {
      title: "مدیریت برچسب ها",
      authRequired: true,
      isAdmin: true,
    }
  },
  {
    path: '/dashboard/comments',
    name: 'comments',
    component: comments,
    meta: {
      title: "بخش نظرات",
      authRequired: true,
      isAdmin: true,
    }
  },

  {
    path: '/dashboard/messages',
    name: 'messages',
    component: messages,
    meta: {
      title: "لیست پیام ها",
      authRequired: true,
      isAdmin: true,
      isOperator: true,
    }
  },

  {
    path: '/dashboard/users/messages/:userId',
    name: 'users.messages',
    component: userMessages,
    meta: {
      title: "پیام های کاربر",
      authRequired: true,
      isAdmin: true,
      props: true,
    }
  },
  {
    path: '/dashboard/profile',
    name: 'profile',
    component: profile,
    meta: {
      title: "پروفایل",
      authRequired: true,
      isAdmin: true,
    }
  },










  {
    path: '/login',
    name: 'login',
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "صفحه ورود",
      guestRequired: true
    }
  },
  {
    path: '/account/delete',
    name: 'account.delete',
    component: () => import("../views/account/delete-account.vue"),
    meta: {
      title: "صفحه حذف حساب کاربری",
      guestRequired: true
    }
  },
];