<script setup>
import { computed, ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import store from "../../state/store"
import PrayerModal from "./PrayerModal.vue"
import PrayerEditModal from "./PrayerEditModal.vue"
import PrayersTable from "./PrayersTable.vue"
import PrayerViewModal from "./PrayerViewModal.vue"

const title = "مدیریت دعاها"
const pageTitle = "داشبورد"

const DEFAULT_PRAYER = {
  id: "",
  prayer_category_id: "",
  category_title: "",
  title: "",
  text: "",
  date: "",
}

const allPrayerCategories = computed(() => store.state.allPrayerCategories)

const prayerModel = ref({ ...DEFAULT_PRAYER })
const showPrayerModal = ref(false)
const showPrayerEditModal = ref(false)
const showPrayerViewModal = ref(false)

const showAddNewModal = () => {
  showPrayerModal.value = true
}

const showEditModal = () => {
  showPrayerEditModal.value = true
}

const showViewModal = () => {
  showPrayerViewModal.value = true
}

function editPrayer(prayer) {
  store.dispatch("getPrayer", prayer.id).then(({ data }) => {
    prayerModel.value = data.data
    showEditModal()
  })
}

const viewPrayer = (prayer) => {
  store.dispatch("getPrayer", prayer.id).then(({ data }) => {
    prayerModel.value = data.data
    showViewModal()
  })
}

const onModalClose = () => {
  prayerModel.value = { ...DEFAULT_PRAYER }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="prayerList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست دعاها</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i> افزودن دعا
                    جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- prayers table  -->

          <PrayersTable @clickEdit="editPrayer" @clickView="viewPrayer" />

          <!-- add and edit prayer modal  -->

          <PrayerModal
            v-model="showPrayerModal"
            :prayer="prayerModel"
            :allPrayerCategories="allPrayerCategories"
            @close="onModalClose"
          />

          <PrayerViewModal
            v-model="showPrayerViewModal"
            :prayer="prayerModel"
            @close="onModalClose"
          />

          <PrayerEditModal
            v-model="showPrayerEditModal"
            :prayer="prayerModel"
            :allPrayerCategories="allPrayerCategories"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.multiselect-clear {
  padding: 0 0 0 var(--ms-px, 0.875rem) !important;
}
.multiselect-caret {
  margin: 0 0 0 var(--ms-px, 0.875rem) !important;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
