import { ref } from 'vue';

export default function useSortData(getDataFunction) {
    const sortField = ref('created_at');
    const sortDirection = ref('desc');

    const sortData = (field) => {
        if (field === sortField.value) {
            sortDirection.value = sortDirection.value === 'desc' ? 'asc' : 'desc';
        } else {
            sortField.value = field;
            sortDirection.value = 'asc';
        }

        getDataFunction();
    };

    return {
        sortField,
        sortDirection,
        sortData,
    };
}
