<script setup>
import { ref, onUpdated, defineProps, defineEmits } from "vue"
import useComments from "../../use/useComments"

const props = defineProps({
  commentProps: {
    required: true,
    type: Object,
  },
})

const commentData = ref({
  id: props.commentProps.id,
  comment: props.commentProps.commentText,
  status: props.commentProps.commentStatus,
})

const emit = defineEmits(["update:modelValue", "close"])

const { show, loading, errors, onSubmit, closeModal } = useComments(
  props,
  emit,
  commentData
)

onUpdated(() => {
  commentData.value = {
    id: props.commentProps.id,
    comment: props.commentProps.commentText,
    status: props.commentProps.commentStatus,
  }
})
</script>

<template>
  <!-- edit comment modal  -->
  <b-modal
    v-model="show"
    @close="closeModal()"
    id="showModal"
    hide-footer
    title=" ویرایش نظر"
    header-class="p-3 bg-light"
    class="v-modal-custom"
    centered
    no-close-on-backdrop
  >
    <b-overlay
      variant="white"
      opacity="0.75"
      blur="2px"
      :show="loading"
      spinner-variant="success"
      rounded="sm"
    >
      <b-card>
        <b-form
          id="addform"
          class="tablelist-form"
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
          <div class="mb-3">
            <label for="commentText" class="form-label">متن نظر کاربر</label>
            <textarea
              class="form-control"
              id="commentText"
              v-model="commentData.comment"
              rows="3"
            ></textarea>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.comment">
                <span>{{ errors.comment[0] }}</span>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <label for="status" class="form-label">وضعیت</label>
            <select
              class="form-control"
              data-trigger
              id="statusfield"
              v-model="commentData.status"
            >
              <option value="">وضعیت</option>
              <option value="0">تائید نشده</option>
              <option value="1">تائید شده</option>
            </select>
            <div v-if="errors">
              <div class="invalid-feedback" v-if="errors.status">
                <span>{{ errors.status[0] }}</span>
              </div>
            </div>
          </div>

          <div class="hstack gap-2 justify-content-end">
            <b-button
              type="button"
              id="closemodal"
              variant="light"
              @click="closeModal"
            >
              بستن
            </b-button>
            <b-button type="submit" variant="success"> ویرایش نظر </b-button>
          </div>
        </b-form>
      </b-card>
    </b-overlay>
  </b-modal>
</template>
