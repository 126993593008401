import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"
import useSortData from './useSortData';
import useDelete from './useDelete';

export default function usePrayers(props, emit, prayerData) {

    // Get toast interface
    const toast = useToast()

    const search = ref("")
    const createdAt = ref("")
    const categoryId = ref("")
    const perPage = ref(10)
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getPrayers = (url = null) => {
        store.dispatch("getPrayers", {
            url,
            search: search.value,
            createdAt: createdAt.value,
            categoryId: categoryId.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true
        if (prayerData.value.id) {
            store.dispatch('updatePrayer', prayerData.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("دعا با موفقیت به روز شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getPrayers")
                        closeModal()
                    }
                })

        } else {
            store
                .dispatch("createPrayer", prayerData.value)
                .then((response) => {
                    loading.value = false
                    if (response.status === 201) {
                        console.log('Yes');
                        toast.success("دعا مورد نظر با موفقیت اضافه شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getPrayers")
                        closeModal()
                    }
                })
                .catch((err) => {
                    loading.value = false
                    errors.value = err.response.data.errors
                })
        }
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }

    const { sortField, sortDirection, sortData } = useSortData(getPrayers);

    const sortPrayers = (field) => {
        sortData(field);
        getPrayers();
    };

    const deletePrayer = (prayer) => {
        const { confirmDelete } = useDelete(
            () => store.dispatch("deletePrayer", prayer),
            'دعا مورد نظر حذف شد.',
            getPrayers
        );

        confirmDelete();
    };


    return {
        show,
        search,
        createdAt,
        categoryId,
        perPage,
        sortField,
        sortDirection,
        loading,
        errors,
        getPrayers,
        onSubmit,
        closeModal,
        sortPrayers,
        deletePrayer,
    }
}