<script setup>
import { ref, reactive, onMounted } from "vue"
import { useToast } from "vue-toastification"
import router from "../../router"

import Layout from "../../layouts/main.vue"

import store from "../../state/store"

// Get toast interface
const toast = useToast()

const user = ref([])

const passwordData = reactive({
  oldpassword: "",
  newpassword: "",
  newpassword_confirmation: "",
})

const confirmpasswordError = ref(null)
const oldPasswordError = ref(null)
const errors = ref({})
const loading = ref(false)

onMounted(async () => {
  const data = await store.dispatch("getCurrentUser")
  user.value = {
    ...data.user,
  }
})

const onSubmit = () => {
  loading.value = true
  store
    .dispatch("updateProfileInfo", {
      user,
    })
    .then((response) => {
      loading.value = false
      if (response.status === 200) {
        toast.success("اطلاعات شما با موفقیت به روز شد!", {
          timeout: 3000,
        })
      }
    })
    .catch((err) => {
      loading.value = false
      errors.value = err.response.data.errors
    })
}

const changePassword = async () => {
  loading.value = true
  if (passwordData.newpassword !== passwordData.newpassword_confirmation) {
    confirmpasswordError.value = "کلمه عبور جدید و تایید آن باید یکسان باشد!"
    return
  }
  try {
    const response = await store.dispatch("changePassword", passwordData)

    if (response.status === 200) {
      toast.success("کلمه عبور با موفقیت تغییر یافت.", {
        timeout: 3000,
      })

      await store.dispatch("logout")
      router.push({ name: "login" })
    }
  } catch (err) {
    oldPasswordError.value = err.response.data.Error
    loading.value = false
    errors.value = err.response.data.errors
  }
}
</script>

<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img"></div>
    </div>

    <b-row>
      <b-col xxl="3">
        <b-card no-body class="mt-n5">
          <b-card-body class="p-4">
            <div class="text-center">
              <div
                class="profile-user position-relative d-inline-block mx-auto mb-4"
              >
                <img
                  src="@/assets/images/users/avatar-1.jpg"
                  class="rounded-circle avatar-xl img-thumbnail user-profile-image shadow"
                  alt="user-profile-image"
                />
              </div>
              <h5 class="fs-16 mb-1">
                {{ user.name ? user.name : "" }}
              </h5>
              <p class="text-muted mb-0" v-if="user.ur && user.ur === 'admin'">
                مدیر سیستم
              </p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col xxl="9">
        <b-overlay
          variant="white"
          opacity="0.75"
          blur="2px"
          :show="loading"
          spinner-variant="success"
          rounded="sm"
        >
          <b-card no-body class="mt-xxl-n5">
            <b-card-header>
              <ul
                class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
                role="tablist"
              >
                <li class="nav-item">
                  <b-link
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#personalDetails"
                    role="tab"
                  >
                    <i class="fas fa-home"></i>
                    اطلاعات شخصی
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#changePassword"
                    role="tab"
                  >
                    <i class="far fa-user"></i>
                    تغییر کلمه عبور
                  </b-link>
                </li>
              </ul>
            </b-card-header>
            <b-card-body class="p-4">
              <div class="tab-content">
                <div
                  class="tab-pane active"
                  id="personalDetails"
                  role="tabpanel"
                >
                  <form @submit.prevent="onSubmit">
                    <b-row>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="nameInput" class="form-label"
                            >نام</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="nameInput"
                            placeholder="نام خود را وارد کنید"
                            v-model="user.name"
                          />
                          <div v-if="errors">
                            <div class="invalid-feedback" v-if="errors.name">
                              <span>{{ errors.name[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="6">
                        <div class="mb-3">
                          <label for="emailInput" class="form-label"
                            >ایمیل</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="emailInput"
                            placeholder="ایمیل خود را وارد کنید"
                            v-model="user.email"
                          />
                          <div v-if="errors">
                            <div class="invalid-feedback" v-if="errors.email">
                              <span>{{ errors.email[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col lg="12">
                        <div class="hstack gap-2 justify-content-end">
                          <b-button
                            type="submit"
                            variant="primary"
                            @click="updatedata"
                          >
                            ویرایش
                          </b-button>
                          <b-button type="button" variant="soft-success">
                            لغو
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </div>
                <div class="tab-pane" id="changePassword" role="tabpanel">
                  <form @submit.prevent="changePassword">
                    <b-alert
                      v-model="confirmpasswordError"
                      variant="danger"
                      class="mt-3"
                      dismissible
                      >{{ confirmpasswordError }}</b-alert
                    >
                    <b-row class="g-2">
                      <b-col lg="4">
                        <div>
                          <label for="oldpasswordInput" class="form-label"
                            >کلمه عبور قبلی*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="oldpasswordInput"
                            placeholder="کلمه عبور قبلی"
                            v-model="passwordData.oldpassword"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.oldpassword"
                            >
                              <span>{{ errors.oldpassword[0] }}</span>
                            </div>
                          </div>
                          <div class="invalid-feedback" v-if="oldPasswordError">
                            <span>{{ oldPasswordError }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <div>
                          <label for="newpasswordInput" class="form-label"
                            >کلمه عبور جدید*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="newpasswordInput"
                            placeholder="کلمه عبور جدید"
                            v-model="passwordData.newpassword"
                          />
                          <div v-if="errors">
                            <div
                              class="invalid-feedback"
                              v-if="errors.newpassword"
                            >
                              <span>{{ errors.newpassword[0] }}</span>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col lg="4">
                        <div>
                          <label for="confirmpasswordInput" class="form-label"
                            >تکرار کلمه عبور*</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            id="confirmpasswordInput"
                            placeholder="تکرار کلمه عبور "
                            v-model="passwordData.newpassword_confirmation"
                          />
                        </div>
                      </b-col>
                      <b-col lg="12">
                        <div class="text-end">
                          <b-button type="submit" variant="success">
                            تغییر کلمه عبور
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.profile-setting-img {
  height: 125px !important;
}
</style>
