import { createStore } from 'vuex'

import modules from './modules'

import state from './state'
import * as authActions from './actions/authActions'
import * as adminActions from './actions/adminActions'
import * as commonActions from './actions/commonActions'
import * as mutations from './mutations'


const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',

  state,
  actions: {
    ...authActions,
    ...adminActions,
    ...commonActions
  },
  mutations,
  getters: {
    loggedIn(state) {
      return state.user.loggedIn;
    },
    isAdmin(state) {
      return state.user.isAdmin;
    },
  },
})

export default store

