<script setup>
import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import MessagesTable from "./MessagesTable.vue"

const title = "مدیریت پیام های کاربران"
const pageTitle = "داشبورد"
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="messageList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست پیام های کاربران</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2"></div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- messages table  -->

          <MessagesTable />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
