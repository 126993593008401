import { ref, computed } from "vue";
import { useToast } from "vue-toastification"

import store from "../state/store"


export default function useComments(props, emit, commentData) {

    // Get toast interface
    const toast = useToast()

    const commentStatus = ref("")
    const search = ref("")
    const perPage = ref(10)
    const sortField = ref("updated_at")
    const sortDirection = ref("desc")
    const loading = ref(false)
    const errors = ref({})


    const show = computed({
        get: () => props.modelValue,
        set: (value) => emit("update:modelValue", value),
    })

    const getComments = (url = null) => {
        store.dispatch("getComments", {
            url,
            search: search.value,
            status: commentStatus.value,
            per_page: perPage.value,
            sort_field: sortField.value,
            sort_direction: sortDirection.value,
        })
    }

    const onSubmit = () => {
        loading.value = true

        if (commentData.value.id) {
            store.dispatch('updateComment', commentData.value)
                .then(response => {
                    loading.value = false;
                    if (response.status === 200) {
                        toast.success("نظر موردنظر با موفقیت به روزرسانی شد!", {
                            timeout: 3000,
                        })
                        store.dispatch("getCommentCount")
                        store.dispatch("getComments")
                        closeModal()
                    }
                })
        }
    }

    const closeModal = () => {
        show.value = false
        errors.value = {}
        emit("close")
    }


    return {
        show,
        commentStatus,
        search,
        perPage,
        loading,
        errors,
        getComments,
        onSubmit,
        closeModal,
    }
}