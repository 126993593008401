<script setup>
import { ref } from "vue"

import Layout from "../../layouts/main.vue"
import PageHeader from "@/components/page-header"

import MassModal from "./MassModal.vue"
import MassEditModal from "./MassEditModal.vue"
import MassesTable from "./MassesTable.vue"
import MassViewModal from "./MassViewModal.vue"

const title = "مدیریت آیین های قربانی"
const pageTitle = "داشبورد"

const DEFAULT_MASS = {
  id: "",
  text: "",
  date: "",
}

const massModel = ref({ ...DEFAULT_MASS })
const showMassModal = ref(false)
const showMassEditModal = ref(false)
const showMassViewModal = ref(false)

const showAddNewModal = () => {
  showMassModal.value = true
}

const showEditModal = () => {
  showMassEditModal.value = true
}

const showViewModal = () => {
  showMassViewModal.value = true
}

const editMass = (mass) => {
  massModel.value = mass
  showEditModal()
}

const viewMass = (mass) => {
  massModel.value = mass
  showViewModal()
}

const onModalClose = () => {
  massModel.value = { ...DEFAULT_MASS }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :pageTitle="pageTitle" />
    <b-row>
      <b-col lg="12">
        <b-card no-body id="massList">
          <b-card-header class="border-bottom-dashed">
            <b-row class="g-4 align-items-center">
              <b-col sm>
                <div>
                  <h5 class="card-title mb-0">لیست آیین های قربانی</h5>
                </div>
              </b-col>
              <b-col sm="auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <b-button
                    type="button"
                    variant="success"
                    class="add-btn me-1"
                    id="create-btn"
                    @click="showAddNewModal()"
                  >
                    <i class="ri-add-line align-bottom me-1"></i>افزودن آیین قربانی
                    جدید
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-header>

          <!-- masses table  -->

          <MassesTable @clickEdit="editMass" @clickView="viewMass" />

          <!-- add and edit mass modal  -->

          <MassModal
            v-model="showMassModal"
            :mass="massModel"
            @close="onModalClose"
          />

          <MassViewModal
            v-model="showMassViewModal"
            :mass="massModel"
            @close="onModalClose"
          />

          <MassEditModal
            v-model="showMassEditModal"
            :mass="massModel"
            @close="onModalClose"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<style>
.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.9rem 0.5rem 2.7rem !important;
  background-position: left 0.9rem center !important;
}

.invalid-feedback {
  display: block !important;
}
</style>
